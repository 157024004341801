const defaultTheme = require('tailwindcss/defaultTheme');
const {
  generateButtonClassSafelist,
} = require('./src/components/_global/BalBtn/button-options.js');

module.exports = {
  content: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],

  safelist: [
    ...generateButtonClassSafelist(),
    // https://tailwindcss.com/docs/content-configuration#safelisting-classes
    // https://github.com/tailwindlabs/tailwindcss/discussions/10079
    { pattern: /^mr/ },
    { pattern: /^w/ },
  ],
  darkMode: 'class',
  theme: {
    fontFamily: {
      body: [
        'Inter-Variable',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Helvetica',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
      ],
      display: [
        '"Tiempos Headline Medium"',
        'Times',
        '"Times New Roman"',
        'serif',
      ],
    },
    boxShadow: {
      sm: '0 2px 4px 0 rgba(0,0,0,0.05)',
      DEFAULT:
        '0px 4px 6px -1px rgba(0, 0, 0, 0.05), 0px 2px 4px -1px rgba(0, 0, 0, 0.05)',
      lg: '0 2px 4px 0 rgba(0,0,0,0.03), 0 10px 40px 0 rgba(0,0,0,0.05)',
      xl: '0 2px 4px 0 rgba(0,0,0,0.05), 0 0px 40px 0 rgba(0,0,0,0.1)',
      '2xl': '0px 25px 50px -12px rgba(0, 0, 0, 0.1)',
      '3xl':
        '0px 10px 10px -5px rgba(0, 0, 0, 0.02), 0px 20px 25px -5px rgba(0, 0, 0, 0.03)',
      '4xl': '25px 25px 50px -12px rgba(0, 0, 0, 0.03)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.05)',
      none: 'none',
    },
    borderColor: theme => ({
      ...theme('colors'),
      DEFAULT: theme('colors.gray.100', 'currentColor'),
    }),
    screens: {
      xs: '440px',
      ...defaultTheme.screens,
    },
    extend: {
      flex: {
        0: '0 0 auto',
      },
      height: {
        112: '28rem',
      },
      borderRadius: {
        '2xl': '1.25rem',
      },
      colors: {
        gray: {
          50: `rgb(var(--color-gray-50) )`,
          100: `rgb(var(--color-gray-100) )`,
          200: `rgb(var(--color-gray-200) )`,
          300: `rgb(var(--color-gray-300) )`,
          400: `rgb(var(--color-gray-400) )`,
          500: `rgb(var(--color-gray-500) )`,
          600: `rgb(var(--color-gray-600) )`,
          700: `rgb(var(--color-gray-700) )`,
          800: `rgb(var(--color-gray-800) )`,
          850: `rgb(var(--color-gray-850) )`,
          900: `rgb(var(--color-gray-900) )`,
        },
        primary: {
          50: `rgb(var(--color-primary-50) )`,
          100: `rgb(var(--color-primary-100) )`,
          200: `rgb(var(--color-primary-200) )`,
          300: `rgb(var(--color-primary-300) )`,
          400: `rgb(var(--color-primary-400) )`,
          500: `rgb(var(--color-primary-500) )`,
          600: `rgb(var(--color-primary-600) )`,
          700: `rgb(var(--color-primary-700) )`,
          800: `rgb(var(--color-primary-800) )`,
          900: `rgb(var(--color-primary-900) )`,
        },
        'primary-dark': {
          50: `rgb(var(--color-primary-dark-50) )`,
          100: `rgb(var(--color-primary-dark-100) )`,
          200: `rgb(var(--color-primary-dark-200) )`,
          300: `rgb(var(--color-primary-dark-300) )`,
          400: `rgb(var(--color-primary-dark-400) )`,
          500: `rgb(var(--color-primary-dark-500) )`,
          600: `rgb(var(--color-primary-dark-600) )`,
          700: `rgb(var(--color-primary-dark-700) )`,
          800: `rgb(var(--color-primary-dark-800) )`,
          900: `rgb(var(--color-primary-dark-900) )`,
        },
        blue: {
          50: `rgb(var(--color-blue-50) )`,
          100: `rgb(var(--color-blue-100) )`,
          200: `rgb(var(--color-blue-200) )`,
          300: `rgb(var(--color-blue-300) )`,
          400: `rgb(var(--color-blue-400) )`,
          500: `rgb(var(--color-blue-500) )`,
          600: `rgb(var(--color-blue-600) )`,
          700: `rgb(var(--color-blue-700) )`,
          800: `rgb(var(--color-blue-800) )`,
          900: `rgb(var(--color-blue-900) )`,
        },
        pink: {
          50: `rgb(var(--color-pink-50) )`,
          100: `rgb(var(--color-pink-100) )`,
          200: `rgb(var(--color-pink-200) )`,
          300: `rgb(var(--color-pink-300) )`,
          400: `rgb(var(--color-pink-400) )`,
          500: `rgb(var(--color-pink-500) )`,
          600: `rgb(var(--color-pink-600) )`,
          700: `rgb(var(--color-pink-700) )`,
          800: `rgb(var(--color-pink-800) )`,
          900: `rgb(var(--color-pink-900) )`,
        },
        purple: {
          50: `rgb(var(--color-purple-50) )`,
          100: `rgb(var(--color-purple-100) )`,
          200: `rgb(var(--color-purple-200) )`,
          300: `rgb(var(--color-purple-300) )`,
          400: `rgb(var(--color-purple-400) )`,
          500: `rgb(var(--color-purple-500) )`,
          600: `rgb(var(--color-purple-600) )`,
          700: `rgb(var(--color-purple-700) )`,
          800: `rgb(var(--color-purple-800) )`,
          900: `rgb(var(--color-purple-900) )`,
        },
        yellow: {
          50: `rgb(var(--color-yellow-50) )`,
          100: `rgb(var(--color-yellow-100) )`,
          200: `rgb(var(--color-yellow-200) )`,
          300: `rgb(var(--color-yellow-300) )`,
          400: `rgb(var(--color-yellow-400) )`,
          500: `rgb(var(--color-yellow-500) )`,
          600: `rgb(var(--color-yellow-600) )`,
          700: `rgb(var(--color-yellow-700) )`,
          800: `rgb(var(--color-yellow-800) )`,
          900: `rgb(var(--color-yellow-900) )`,
        },
        orange: {
          50: `rgb(var(--color-orange-50) )`,
          100: `rgb(var(--color-orange-100) )`,
          200: `rgb(var(--color-orange-200) )`,
          300: `rgb(var(--color-orange-300) )`,
          400: `rgb(var(--color-orange-400) )`,
          500: `rgb(var(--color-orange-500) )`,
          600: `rgb(var(--color-orange-600) )`,
          700: `rgb(var(--color-orange-700) )`,
          800: `rgb(var(--color-orange-800) )`,
          900: `rgb(var(--color-orange-900) )`,
        },
      },
    },
  },
  plugins: [],
};
